main {
  display: flex;
  margin: 1em;
}

section {
  flex: 10 10 0;
  padding-bottom: 2em;
  width: 100%;
}

aside {
  display: block;
  flex: 3 3 0;
  margin: 1em;
}

header {
  padding: 5em 5em 1em 5em;
}

table {
  max-width: 100%;
}

.table-container {
  overflow-x: auto;
}

.dark-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 20%;
}
.dark-light-mode-text {
  max-width: 100%;
  width: 10em;
  padding-top: 1em;
  margin-right: 2.5em;
}

@media screen and (max-width: 768px) {
  aside {
    display: none;
  }
  header {
    padding: 3em 0 1em 0;
  }
  .dark-button-container {
    height: 60px;
    padding-right: 5%;
    padding-top: 1em;
    display: flex;
    flex-direction: row-reverse;
  }
  .dark-light-mode-text {
    max-width: 100%;
    width: 10em;
    margin-right: 0;
  }
}

.title {
  text-align: center;
  font-size: 70px;
}

.subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 26px;
}

footer {
  text-align: center;
  padding-bottom: 10px;
}

.no-caret {
  font-size: 26px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-caret::after {
  display: none !important;
}
